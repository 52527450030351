import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import MFooter from "../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import "./MHomePage.scss";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";

const MHomePage = ({ navigateForm }: { navigateForm: Function }) => {
  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    dots: false,
  };

  const textimonialArray = [
    {
      name: "Walsa Jones",
      review:
        "I trust Ahalia for my insurance requirements. They stand by you when you have a claim.",
    },
    {
      name: "Sangeetha Nair",
      review:
        "I got excellent service when I had a claim for my car. They had arranged for cashless facility even though I did not take the policy from the dealership.",
    },
    {
      name: "Radhakrishnan",
      review:
        "I received best advice for my personal health insurance and the best service while I had a hospitalisation claim.",
    },
  ];

  const partnersArray = [
    "./images/insurers/aditya_birla_capital.svg",
    "./images/insurers/HDFC-ERGO-health.svg",
    "./images/insurers/Tata-AIA-Life-Insurance-Company-Limited.svg",
    "./images/insurers/HDFC-Standard-Life-Insurance-Co-Ltd.svg",
    "./images/insurers/kotak_general_insurance.svg",
    "./images/insurers/Liberty-Videocon-General-Insurance-Co-Ltd.svg",
    "./images/insurers/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Raheja-QBE.svg",
    "./images/insurers/Royal_Sundaram.svg",
  ];
  return (
    <Box className="homepageWrapper_m">
      <MNavbar />

      <Box className="product-header-mobile">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"15px"}>
            <h5>LIFE & BEYOND</h5>
            <h3>
              We Provide <span>Solutions Formulated With Care</span> To Protect
              Your Wealth And Family.
            </h3>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <Box className="products-box">
              <ul className="productList mb-4">
                <li>
                  <Link
                    className="healthproduct"
                    onClick={() => {
                      window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                    }}
                  >
                    <img src="./images/health-product-icon.svg" alt="" />
                    <h6>Health Insurance</h6>
                    <p>Individual, Family & Parents</p>
                    <span>
                      covers <strong>COVID-19</strong>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="termproduct"
                    onClick={() => {
                      window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                    }}
                  >
                    <img src="./images/term-product-icon.svg" alt="" />
                    <h6>Term Insurance </h6>
                    <p>Save tax upto 75,000</p>
                    <span>
                      covers <strong> COVID-19</strong>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="carproduct"
                    onClick={() => {
                      window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                    }}
                  >
                    <img src="./images/car-product-icon.svg" alt="" />
                    <h6>Car Insurance </h6>
                    <p>New, Renew & Expired</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className="bikeproduct"
                    onClick={() => {
                      window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                    }}
                  >
                    <img src="./images/bike-product-icon.svg" alt="" />
                    <h6>Bike Insurance </h6>
                    <p>New, Renew & Expired</p>
                  </Link>
                </li>
                <li className="disabled">
                  <Link className="investmentproduct">
                    <img src="./images/investment-product-icon.svg" alt="" />
                    <h6>Investment Plans </h6>
                    <p>ULIP & Guaranteed</p>
                  </Link>
                </li>

                <li className="disabled">
                  <Link className="travelproduct">
                    <img src="./images/travel-product-icon.svg" alt="" />
                    <h6>Travel Insurance </h6>
                    <p>Individual & Group</p>
                  </Link>
                </li>
              </ul>

              <Box textAlign={"center"} marginBottom={"16px"}>
                <img
                  src="./images/header-illustration.svg"
                  alt=""
                  className="header-illustration"
                />
              </Box>
              <Box className="irda-box">
                <img src="./images/irda-logo.png" alt="" />
                <p className="licence">
                  Licensed by
                  <span>IRDA/ DB 749/18</span>
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="work-section-mobile">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
            <h4>
              How to Work With <span>Policy First?</span>
            </h4>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} marginBottom={"44px"}>
            <ul className="workPolicy-list">
              <li>
                <span className="step1">
                  step <strong>01</strong>
                </span>
                <Box>
                  <h5>Choose The Best Plan That Suits You</h5>
                  <p>
                    No pressure for targets and no minimum business volume for{" "}
                  </p>
                </Box>
              </li>
              <li>
                <span className="step2">
                  step <strong>02</strong>
                </span>
                <Box>
                  <h5>Choose The Best Plan That Suits You</h5>
                  <p>
                    No pressure for targets and no minimum business volume for{" "}
                  </p>
                </Box>
              </li>
              <li>
                <span className="step3">
                  step <strong>03</strong>
                </span>
                <Box>
                  <h5>Choose The Best Plan That Suits You</h5>
                  <p>
                    No pressure for targets and no minimum business volume for{" "}
                  </p>
                </Box>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <img src="./images/work-poilcy.svg" alt="" className="work-img" />
          </Grid>
        </Grid>
      </Box>

      <Box className="claim_assistance_sec_mobile">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="claimBox">
              <Box className="top_box">
                <h4 className="mb-4" style={{ textAlign: "center" }}>
                  Buy Online… <span>Get Serviced Locally…</span>
                </h4>
                <p>
                  We understand that in the unfortunate event of a claim, you
                  always look for assistance at your neighbourhood. We always
                  strive to ensure our presence at your locale when you are in
                  need. Should you require any claim service in your local area,
                  Our Point of Service Person in your area will provide the
                  necessary support and advice in the event of a claim.
                </p>
              </Box>
              <Box className="grey_box">
                <img src="./images/claim.svg" alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="products_sec_mobile">
        <Grid
          container
          columnSpacing={3}
          className="row"
          justifyContent={"center"}
        >
          <Grid xs={12} textAlign={"center"}>
            <h4 className="mb-7">
              Read About <span>Products</span>
            </h4>
            <ul className="product_list">
              <li>
                <Link>
                  <img src="./images/product_icon_car.svg" alt="" />
                  <span className="car">Car</span>
                </Link>
              </li>
              <li>
                <Link>
                  <img src="./images/product_icon_bike.svg" alt="" />
                  <span className="bike">Bike</span>
                </Link>
              </li>
              <li>
                <Link>
                  <img src="./images/product_icon_health.svg" alt="" />
                  <span className="health">Health</span>
                </Link>
              </li>
              <li>
                <Link>
                  <img src="./images/product_icon_term.svg" alt="" />
                  <span className="term">Term</span>
                </Link>
              </li>
              <li>
                <Link>
                  <img src="./images/product_icon_investment.svg" alt="" />
                  <span className="investment">Investment</span>
                </Link>
              </li>
              <li>
                <Link>
                  <img src="./images/product_icon_travel.svg" alt="" />
                  <span className="travel">Travel</span>
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="testimonials-sec-mobile">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"32px"}>
            <h4>
              What People <span>Say About Us</span>
            </h4>
          </Grid>
          <Grid xs={12} marginBottom={"12px"}>
            <Slider {...settings}>
              {textimonialArray?.map((data, index) => (
                <Box paddingRight={"12px"}>
                  <Box className="testimonial_slider">
                    <h5>{data.name}</h5>
                    <p>{data.review}</p>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <img
              src="./images/testimonial-pic.svg"
              alt=""
              style={{ width: "100%", maxWidth: "314px" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="partners-sec-mobile">
        <Grid
          container
          columnSpacing={3}
          className="row"
          justifyContent={"center"}
        >
          <Grid xs={12} textAlign={"center"}>
            <Box className="partners-box">
              <h4 className="mb-7">
                Our <span>Partners</span>
              </h4>
              <ul className="partners-list">
                {partnersArray?.map((data, index) => (
                  <li>
                    <img src={data} alt="" className="partner_img" />
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <MFooter />
    </Box>
  );
};

export default MHomePage;
